@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/810c6c30e724c91e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/195e0b882fc4bce2-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/04a97d3d3f1adba7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/e133f720523b86b4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/e83e96a951c251f3-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/332ef265964147d9-s.p.ttf) format('truetype');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/ea5a1b95b31d49ea-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/0f5baad6fde6c1ee-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/4359a0ef13484923-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/f06cd19e1d31c54d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/2d45b786aadeb34e-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__moderat_f9df4f';
src: url(/_next/static/media/2d6bab4f4144edba-s.p.ttf) format('truetype');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: '__moderat_Fallback_f9df4f';src: local("Arial");ascent-override: 97.24%;descent-override: 19.47%;line-gap-override: 0.00%;size-adjust: 102.84%
}.__className_f9df4f {font-family: '__moderat_f9df4f', '__moderat_Fallback_f9df4f', system-ui, sans
}.__variable_f9df4f {--font-moderat: '__moderat_f9df4f', '__moderat_Fallback_f9df4f', system-ui, sans
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/becef38fbdb21edd-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/09eb5f3f7a80ca06-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/66069eae5e7a632c-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/e57555cc80a8d6fe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/9ce834f7e3302167-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/9ce834f7e3302167-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/488a654cab76e885-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/f0b3f3812182710b-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/954f06f8a39ef202-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/89cbfb1fad2105d1-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/5a6db958e0120874-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_6356fc';
src: url(/_next/static/media/3867084e4e6ae212-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__newSpirit_Fallback_6356fc';src: local("Arial");ascent-override: 90.53%;descent-override: 24.05%;line-gap-override: 0.00%;size-adjust: 107.39%
}.__className_6356fc {font-family: '__newSpirit_6356fc', '__newSpirit_Fallback_6356fc', serif
}.__variable_6356fc {--font-spirit: '__newSpirit_6356fc', '__newSpirit_Fallback_6356fc', serif
}

@font-face {
font-family: '__flow_8d4cda';
src: url(/_next/static/media/55f4a0fb6b7a155f-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__flow_8d4cda';
src: url(/_next/static/media/55f4a0fb6b7a155f-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__flow_Fallback_8d4cda';src: local("Arial");ascent-override: 41.60%;descent-override: 1.73%;line-gap-override: 0.00%;size-adjust: 113.03%
}.__className_8d4cda {font-family: '__flow_8d4cda', '__flow_Fallback_8d4cda', sans
}.__variable_8d4cda {--font-flow: '__flow_8d4cda', '__flow_Fallback_8d4cda', sans
}

